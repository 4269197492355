export interface ImageCompressionOption {
    sizeThreshold: number;
    ratio: number;
    quality: number;
}

export const DefaultImageCompressionOption: ImageCompressionOption = {
    sizeThreshold: 1024 * 1024, // 1MB
    ratio: 50,
    quality: 50,
};
