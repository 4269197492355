export function dataUrlToBlob(dataUrl: string): Blob {
    const split = dataUrl.split(',');
    const type = split[0].replace('data:', '').replace(';base64', '');
    const byteString = atob(split[1]);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i += 1) {
        ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([arrayBuffer], { type });
}

export async function blobToDataUrl(
    blob: Blob,
): Promise<string | ArrayBuffer | null> {
    let reader = new FileReader();
    reader.readAsDataURL(blob);
    await new Promise<void>(
        (resolve) =>
            (reader.onload = function () {
                resolve();
            }),
    );
    return reader.result;
}

export function imageDataToDataUrl(
    { data, width, height }: ImageData,
    type: string,
): string {
    if (typeof width === 'undefined' || typeof height === 'undefined') {
        width = height = Math.sqrt(data.length / 4);
    }

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    canvas.width = width;
    canvas.height = height;

    if (ctx) {
        const imgData = ctx.createImageData(width, height);
        imgData.data.set(data);
        ctx.putImageData(imgData, 0, 0);
    }

    return canvas.toDataURL(type);
}
