import { Injectable } from '@angular/core';
import { isMediaImage } from '@interacta-shared/util-common';
import { DOC_ORIENTATION, NgxImageCompressService } from 'ngx-image-compress';
import { DefaultImageCompressionOption } from './image-compression.model';
import { blobToDataUrl, dataUrlToBlob } from './image-compression.utils';

@Injectable({ providedIn: 'root' })
export class ImageCompressionService {
    constructor(private imageCompress: NgxImageCompressService) {}

    async compressImageBlob(
        file: Blob,
        orientation = DOC_ORIENTATION.Default,
        options = DefaultImageCompressionOption,
    ): Promise<Blob | undefined> {
        if (isMediaImage(file.type) && file.size > options.sizeThreshold) {
            const dataUrl = await blobToDataUrl(file);
            if (typeof dataUrl === 'string') {
                const compressedDataUrl = await this.imageCompress.compressFile(
                    dataUrl,
                    orientation,
                    options.ratio,
                    options.quality,
                );

                const compressedBlob = dataUrlToBlob(compressedDataUrl);
                console.debug(
                    `Image compression done: ${file.size} --> ${compressedBlob.size}`,
                );

                return compressedBlob;
            }
        }
    }

    async compressImageFile(
        file: File,
        options = DefaultImageCompressionOption,
    ): Promise<File | undefined> {
        if (isMediaImage(file.type) && file.size > options.sizeThreshold) {
            const dataUrl = await blobToDataUrl(file);
            if (typeof dataUrl === 'string') {
                const orientation =
                    await this.imageCompress.getOrientation(file);
                const compressedBlob = await this.compressImageBlob(
                    file,
                    orientation,
                    options,
                );
                if (compressedBlob) {
                    const compressed = new File([compressedBlob], file.name, {
                        type: file.type,
                        lastModified: file.lastModified,
                    });
                    return compressed;
                }
            }
        }
    }
}
